$color-rca-blue: rgb(41, 83, 168);
$color-rca-blue-hover: rgb(22, 60, 137);
$color-rca-gray: rgb(173, 185, 202);
$color-rca-grayrow: rgb(228, 228, 228);
$color-readable-dark: #333;
$color-readable-dark-dim: #555;
$color-alert-default: #d0d0d0;
$color-alert-success: #5cbd6a;
$color-alert-warning: #dbab31;
$color-alert-danger: #e14d4d;
$color-alert-black: #000000;
$color-alert-gray: #bbbbbb;
$color-input: #fcfdfd;
$color-input-hover: #ffffff;
$border-color1: #70bfbf;
$border-color2: #e2e2e2;
$border-color3: #e8e7e7;
$border-color4: #888787;
$default-bg: #f5f5f5;
$gray-bg: #f8f8fe;
$text-color: #464646;
$green: #488d68;
$red: #db0b0b;
$redbg: #fde6e8;
$blue: #030575;
$complete: #0d74e3;

$font-primary: "Mada", sans-serif;
$font-body: "Poppins", "Arial", Helvetica, sans-serif;
$font-patient: "Libre Franklin", sans-serif;
$font-data: "Roboto", sans-serif;
$default-font: "Montserrat";

$font-size-sm: 12px;
$font-size-md: 14px;
$font-size-lg: 16px;
$font-size-xl: 18px;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$width-sidebar: 88px;
$height-input: 38px;
$height-label: 22px;
$box-shadow-button: 0 3px 7px 0 rgba(21, 21, 21, 0.21);
$border-radius-input: 6px;
$border-radius-lg: 9px;
$transition-duration-default: 300ms;
$transition-duration-input: 110ms;

$z-index-0: -100; /* Video */
$z-index-1: 100; /* Backgrounds */
$z-index-2: 200; /* Content, buttons */
$z-index-3: 300; /* Captions */
$z-index-4: 400; /* Meta */
$z-index-5: 500; /* Avatars, logos */
$z-index-6: 600; /* Sidebars, overlays */
$z-index-7: 700; /* Modals, transitions */
$z-index-8: 800; /* Navs */
$z-index-9: 900; /* Topbars, tooltips */
$z-index-10: 1000; /* Dev */

$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;
$screen-xxl-min: 1400px;
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
$screen-lg-max: ($screen-xl-min - 1);
$screen-xl-max: ($screen-xxl-min - 1);

$container-sm: 540px;
$container-md: 720px;
$container-lg: 960px;
$container-xl: 1140px;
$container-xxl: 1320px;

$ease-circ: cubic-bezier(0.11, 0.77, 0.28, 0.92);
$ease-expo: cubic-bezier(0.19, 1, 0.22, 1);
$red-alert: var(--Content-Color-Status-Red-Badge-bg, #db0b0b);
$yellow-alert: #ffce1f;
$highValueBorderColor: #db0b0b;
$mediumValueBorderColor: #b59216;
$highValueBGColor: #fde6e8;
$mediumValueBGColor: #fffae9;
$borderColor5: #e5e5ef;
$greenValueBorderColor: #488d68;
$greenValueBGColor: #f0f9f4;
$green-alert: #66c793;
$table-bg: #eceaea;
$light-gray: #838383;
$high-color: #db0b0b;
$medium-color: #ffce1f;
$normal-color: #66c793;
$high-bg: #feefef;
$medium-bg: #fffae9;
$normal-bg: #f0f9f4;

.monitoringTab {
  height: -moz-available;
  height: -webkit-fill-available;
  height: 100%;
  .css-1q2h7u5,
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    font-family: "Montserrat" !important;
    font-weight: 500 !important;
    font-size: 0.75rem !important;
    text-transform: none !important;
    color: #464646 !important;
    width: 14.28%;
    margin-right: 0% !important;
    padding: 0rem !important;
    background-color: transparent !important;
    height: 50px;
  }

  .css-1q2h7u5.Mui-selected,
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    font-family: "Montserrat" !important;
    font-weight: 600 !important;
    font-size: 0.75rem !important;
    color: #030575 !important;
    width: 14.28%;
    margin-right: 0% !important;
    background-color: white !important;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 0rem !important;
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
    height: 50px;
  }

  .css-69z67c-MuiTabs-scroller {
    border-bottom: 0.125rem solid white !important;
  }

  .css-1aquho2-MuiTabs-indicator {
    background-color: transparent !important;
  }

  .css-heg063-MuiTabs-flexContainer {
    /* justify-content: space-between !important; */
  }

  .css-19kzrtu,
  .css-13xfq8m-MuiTabPanel-root {
    padding: 0 !important;
  }

  .css-ttwr4n {
    background-color: transparent !important;
  }

  .css-12qnib {
    border-bottom: 0.125rem solid white !important;
  }
}

/******************************React Datepicker styles******************************************/
.react-datepicker__day-name {
  font-family: "Montserrat" !important;
  color: #838383 !important;
  font-weight: 500;
  font-size: 0.875rem !important;
}

.react-datepicker__day-names {
  margin-top: 1.6875rem !important;
}

.react-datepicker__day {
  color: $text-color !important;
  font-size: 0.875rem !important;
  font-family: "Montserrat" !important;
  font-weight: 500 !important;
}
.react-datepicker__day--keyboard-selected {
  color: $text-color !important;
  font-size: 0.875rem !important;
  font-family: "Montserrat" !important;
  font-weight: 500 !important;
  background-color: transparent !important;
  outline: none !important;
}

.react-datepicker__day:hover {
  border-radius: 50% !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--selected:hover {
  color: white !important;
  background-color: #030575 !important;
  border-radius: 50% !important;
}

.react-datepicker__day--disabled {
  opacity: 0.4 !important;
}

.react-datepicker__month-container,
.react-datepicker__header {
  background-color: white;
  font-family: "Montserrat" !important;
  padding-top: 0.5rem !important;
  border-radius: 0.25rem !important;
  border-bottom: none !important;
}

.react-datepicker__current-month {
  color: #1d3b4a !important;
  font-size: 0.875rem !important;
  font-family: "Montserrat" !important;
  font-weight: 700 !important;
}
.react-datepicker__navigation-icon::before {
  border-color: #1d3b4a !important;
}
.react-datepicker__navigation {
  margin-top: 1rem !important;
}

.react-datepicker__triangle {
  display: none !important;
}

.react-datepicker-popper {
  padding-top: 0 !important;
  border-radius: 0.25rem !important;
}

.react-datepicker {
  box-shadow: 0rem 0.125rem 0.5rem 0rem #00000026 !important;
  border: none !important;
  border-radius: 0.25rem !important;
}

/***************************************chart styles****************************/
.monitoringTab {
  .highcharts-tooltip {
    background-color: white !important;
    box-shadow: 0px 1px 3px 0px #0000006b !important;
    width: auto !important;
    height: 80px !important;
    gap: 0px !important;
    border-radius: 6px !important;
    opacity: 1 !important;
    padding: 6px;
  }

  .highcharts-label-box,
  .highcharts-tooltip-box {
    visibility: hidden;
  }
  .highcharts-tooltip > span {
    position: relative !important;
    left: 0 !important;
  }
}
.valueLabel {
  font-size: 12px;
  font-family: "Montserrat";
  font-weight: 500;
  border-radius: 11px;
  padding: 2px;
  width: 38px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.highText {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-medium;
  color: white;
  background: $high-color;
  border-radius: 2px;
  min-width: 60px;
  text-align-last: center;
  margin: 0px 4px;
  height: 1.3125rem;
  align-items: center;
  display: flex;
  justify-content: center;
}

.normalText {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-medium;
  color: $text-color;
  background: $normal-color;
  border-radius: 2px;
  min-width: 60px;
  text-align-last: center;
  margin: 0px 4px;
  height: 1.3125rem;
  align-items: center;
  display: flex;
  justify-content: center;
}

.mediumText {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-medium;
  color: $text-color;
  background: $medium-color;
  border-radius: 2px;
  min-width: 60px;
  text-align-last: center;
  margin: 0px 4px;
  height: 1.3125rem;
  align-items: center;
  display: flex;
  justify-content: center;
}

.highValue {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-semibold;
  color: $text-color;
  background: $high-bg;
  border: 0.5px solid $high-color;
  border-radius: 11px;
  width: 38px;
  text-align-last: center;
  height: 1.25rem;
  display: flex;
  text-align: center;
  justify-content: center;
  align-self: center;
  align-items: center;
  padding: 0.25rem;
}

.mediumValue {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-semibold;
  color: $text-color;
  background: $medium-bg;
  border: 0.5px solid #b59216;
  border-radius: 11px;
  width: 38px;
  text-align-last: center;
  height: 1.25rem;
  display: flex;
  text-align: center;
  justify-content: center;
  align-self: center;
  align-items: center;
  padding: 0.25rem;
}

.normalValue {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-semibold;
  color: $text-color;
  background: #f0f9f4;
  border: 0.5px solid #488d68;
  border-radius: 11px;
  width: 38px;
  text-align-last: center;
  height: 1.25rem;
  display: flex;
  text-align: center;
  justify-content: center;
  align-self: center;
  align-items: center;
  padding: 0.25rem;
}

.lowValue {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-semibold;
  color: $text-color;
  background: #e6e6e6;
  border-radius: 11px;
  width: 38px;
  text-align-last: center;
  margin-right: 2px;
  height: 1.25rem;
  display: flex;
  text-align: center;
  justify-content: center;
  align-self: center;
  align-items: center;
  padding: 0.25rem;
}

.unitText {
  font-family: "Montserrat";
  font-size: 0.75rem;
  font-weight: 600;
  color: $text-color;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2px;
}

.tooltipHeaderImage {
  width: 1rem !important;
  height: 1rem !important;
  margin-bottom: 0.25rem !important;
}

.tooltipDate {
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 14.63px;
  text-align: left;
  color: $light-gray;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-tooltip-arrow {
  display: none !important;
}

.dividerSlash {
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 600;
  color: $text-color;
  margin: 0px 2px 0px 2px;
  display: flex;
  align-items: center;
  vertical-align: middle;
}

.graphChart {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  overflow: hidden !important;
}

.monitoringParentContainer,
.createNoteMainDiv {
  /* scrollbar */
  ::-webkit-scrollbar {
    width: 0.3125rem;
    height: 0.3125rem;
  }

  ::-webkit-scrollbar-track {
    -webkit-border-radius: 0.875rem;
    border-radius: 0.875rem;
    background: #f5f5f5 !important;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 0.875rem;
    border-radius: 0.875rem;
    background: rgba(3, 5, 117, 0.63) !important;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(3, 5, 117, 0.63);
  }
}

.spirometryTab {
  .graphChart {
    margin-top: -1.75rem;
  }
}

.toastClassMonitor {
  margin-bottom: 3rem !important;
  .rs-message-container {
    background-color: #464646 !important;
    border-radius: 0.625rem !important;
    width: 19.3125rem !important;
    height: 3.375rem !important;
    padding: 0.5625rem 1rem !important;
    .rs-message-body {
      color: white !important;
      align-items: center !important;
      justify-content: center !important;
      display: flex !important;
    }
    .rs-message-content {
      align-items: center !important;
      justify-content: center !important;
      display: flex !important;
    }
  }
}
