.client-table th,
.client-table td {
  padding: 5px !important;
}

.sorted_col {
  background-color: rgb(217, 225, 242);
  font-weight: 400;
  border-bottom: 1px solid rgb(181, 198, 234) !important;
  border-top: 1px solid var(--color-rca-blue) !important;
  border-left: 1px solid #fbfcfc;
  border-right: 1px solid #fbfcfc;
}

.sorted_col a {
  font-weight: 400;
  font-size: 14px !important;
}

.sorted_col a:hover {
  color: var(--color-rca-blue);
}

.sorted_head {
  border-bottom: 1px solid var(--color-rca-blue) !important;
}

.td-text {
  font-size: 14px !important;
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
}

thead:nth-child(n)
  > .bg-primary.text-white.th-text.text-center
  ~ th:not(.bg-primary.text-white.th-text.text-center) {
  display: none !important;
}
