$color-rca-blue: rgb(41, 83, 168);
$color-rca-blue-hover: rgb(22, 60, 137);
$color-rca-gray: rgb(173, 185, 202);
$color-rca-grayrow: rgb(228, 228, 228);
$color-readable-dark: #333;
$color-readable-dark-dim: #555;
$color-alert-default: #d0d0d0;
$color-alert-success: #5cbd6a;
$color-alert-warning: #dbab31;
$color-alert-danger: #e14d4d;
$color-alert-black: #000000;
$color-alert-gray: #bbbbbb;
$color-input: #fcfdfd;
$color-input-hover: #ffffff;
$border-color1: #70bfbf;
$border-color2: #e2e2e2;
$border-color3: #e8e7e7;
$border-color4: #888787;
$default-bg: #f5f5f5;
$gray-bg: #f8f8fe;
$text-color: #464646;
$green: #488d68;
$red: #db0b0b;
$redbg: #fde6e8;
$blue: #030575;
$complete: #0d74e3;

$font-primary: "Mada", sans-serif;
$font-body: "Poppins", "Arial", Helvetica, sans-serif;
$font-patient: "Libre Franklin", sans-serif;
$font-data: "Roboto", sans-serif;
$default-font: "Montserrat";

$font-size-sm: 12px;
$font-size-md: 14px;
$font-size-lg: 16px;
$font-size-xl: 18px;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$width-sidebar: 88px;
$height-input: 38px;
$height-label: 22px;
$box-shadow-button: 0 3px 7px 0 rgba(21, 21, 21, 0.21);
$border-radius-input: 6px;
$border-radius-lg: 9px;
$transition-duration-default: 300ms;
$transition-duration-input: 110ms;

$z-index-0: -100; /* Video */
$z-index-1: 100; /* Backgrounds */
$z-index-2: 200; /* Content, buttons */
$z-index-3: 300; /* Captions */
$z-index-4: 400; /* Meta */
$z-index-5: 500; /* Avatars, logos */
$z-index-6: 600; /* Sidebars, overlays */
$z-index-7: 700; /* Modals, transitions */
$z-index-8: 800; /* Navs */
$z-index-9: 900; /* Topbars, tooltips */
$z-index-10: 1000; /* Dev */

$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;
$screen-xxl-min: 1400px;
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
$screen-lg-max: ($screen-xl-min - 1);
$screen-xl-max: ($screen-xxl-min - 1);

$container-sm: 540px;
$container-md: 720px;
$container-lg: 960px;
$container-xl: 1140px;
$container-xxl: 1320px;

$ease-circ: cubic-bezier(0.11, 0.77, 0.28, 0.92);
$ease-expo: cubic-bezier(0.19, 1, 0.22, 1);
$red-alert: var(--Content-Color-Status-Red-Badge-bg, #db0b0b);
$yellow-alert: #ffce1f;
$highValueBorderColor: #db0b0b;
$mediumValueBorderColor: #b59216;
$highValueBGColor: #fde6e8;
$mediumValueBGColor: #fffae9;
$borderColor5: #e5e5ef;
$greenValueBorderColor: #488d68;
$greenValueBGColor: #f0f9f4;
$green-alert: #66c793;
$table-bg: #eceaea;
$light-gray: #838383;
$high-color: #db0b0b;
$medium-color: #ffce1f;
$normal-color: #66c793;
$high-bg: #feefef;
$medium-bg: #fffae9;
$normal-bg: #f0f9f4;

.buttonReset {
  -webkit-appearance: none;
  background: transparent;
  // Reset
  border: none;
  color: inherit;
  font: inherit;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  line-height: normal;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 0;
  text-align: inherit;
  width: auto;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  &:focus {
    outline-color: var(--color-rca-blue);
    outline-style: solid;
    outline-width: 1px;
  }
}

.table {
  display: table;
  border: 1px solid var(--color-rca-gray);
  border-collapse: collapse;
  font-size: $font-size-md;
  font-family: $font-primary;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

  thead tr {
    background-color: var(--color-rca-blue);
    color: #ffffff;
    text-align: left;
    font-size: 1rem;
  }
  thead th {
    height: 46px;
    padding: 10px 15px !important;
    font-size: $font-size-md;
  }

  th,
  td {
    padding: 7px 14px !important;
    vertical-align: middle;
    min-width: 50px;
    box-sizing: border-box;
  }

  tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }

  strong {
    font-weight: $font-weight-bold;
  }

  input {
    cursor: pointer;
  }
}

.warning,
label.warning {
  color: $color-alert-warning;
}
.danger,
label.danger {
  color: $color-alert-danger;
}
.success,
label.success {
  color: $color-alert-success;
}
.bold {
  font-weight: $font-weight-bold;
}

.row {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.col {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.disabled {
  cursor: not-allowed !important;
  opacity: 0.5 !important;
}

.pageHeading {
  font-size: 21px;
  font-weight: 900;
  color: var(--color-readable-dark);
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.requiredField {
  &::after {
    content: "*";
    color: $color-alert-danger;
  }
}
