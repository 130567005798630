.required-field::after {
  content: "*";
  color: red;
}

#org_form label {
  font-size: 14px;
}

button {
  font-weight: 700;
}

.rc-btn {
  background-color: rgb(41, 83, 168) !important;
  color: white !important;
  font-size: 14px !important;
}

.rc-btn:hover {
  background-color: rgb(20, 77, 192) !important;
  color: white !important;
}
