.editor-button {
  position: fixed;
}

.hide-edit-button {
  display: none;
}
.pointer {
  cursor: pointer;
}
.tdText:hover {
  color: blue;
}
