.search {
  font-size: 10px;
}

select {
  font-size: 10px;
}
.user-search {
  position: relative;
}
.user-search input {
  padding-left: 25px;
}
.user-search i {
  position: absolute;
  left: 7px;
  top: 50%;
  transform: translate(3px, -50%);
}
.user-search-input {
  width: 100%;
  padding: 2px 0px;
}
.search-inline {
  display: inline-block;
}
.right-text {
  text-align: right;
}
.header-button span {
  font-size: 10px;
}
.btn-glow-secondary {
  box-shadow: 0 0 7px 0 rgb(171 172 174), 0 0 10px 2px rgb(255 255 255);
}
.dropdown-menu a {
  cursor: pointer;
}

.highcharts-tooltip > span table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  max-height: 200px;
  overflow-y: auto;
  max-width: 200px;
}

.highcharts-tooltip > span td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.highcharts-tooltip > span thead tr,
.highcharts-tooltip > span tr:nth-child(even) {
  background: #e5e3e3;
}

.highcharts-tooltip > span {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: auto;
  max-width: 300px;
}

.highcharts-tooltip > th {
  font-weight: 600;
  padding: 0.5em;
  color: #363636;
}

#CPT-chart .highcharts-tooltip > span {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: visible;
  max-width: 1000px !important;
}
