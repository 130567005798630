$color-rca-blue: rgb(41, 83, 168);
$color-rca-blue-hover: rgb(22, 60, 137);
$color-rca-gray: rgb(173, 185, 202);
$color-rca-grayrow: rgb(228, 228, 228);
$color-readable-dark: #333;
$color-readable-dark-dim: #555;
$color-alert-default: #d0d0d0;
$color-alert-success: #5cbd6a;
$color-alert-warning: #dbab31;
$color-alert-danger: #e14d4d;
$color-alert-black: #000000;
$color-alert-gray: #bbbbbb;
$color-input: #fcfdfd;
$color-input-hover: #ffffff;
$border-color1: #70bfbf;
$border-color2: #e2e2e2;
$border-color3: #e8e7e7;
$border-color4: #888787;
$default-bg: #f5f5f5;
$gray-bg: #f8f8fe;
$text-color: #464646;
$green: #488d68;
$red: #db0b0b;
$redbg: #fde6e8;
$blue: #030575;
$complete: #0d74e3;

$font-primary: "Mada", sans-serif;
$font-body: "Poppins", "Arial", Helvetica, sans-serif;
$font-patient: "Libre Franklin", sans-serif;
$font-data: "Roboto", sans-serif;
$default-font: "Montserrat";

$font-size-sm: 12px;
$font-size-md: 14px;
$font-size-lg: 16px;
$font-size-xl: 18px;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$width-sidebar: 88px;
$height-input: 38px;
$height-label: 22px;
$box-shadow-button: 0 3px 7px 0 rgba(21, 21, 21, 0.21);
$border-radius-input: 6px;
$border-radius-lg: 9px;
$transition-duration-default: 300ms;
$transition-duration-input: 110ms;

$z-index-0: -100; /* Video */
$z-index-1: 100; /* Backgrounds */
$z-index-2: 200; /* Content, buttons */
$z-index-3: 300; /* Captions */
$z-index-4: 400; /* Meta */
$z-index-5: 500; /* Avatars, logos */
$z-index-6: 600; /* Sidebars, overlays */
$z-index-7: 700; /* Modals, transitions */
$z-index-8: 800; /* Navs */
$z-index-9: 900; /* Topbars, tooltips */
$z-index-10: 1000; /* Dev */

$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;
$screen-xxl-min: 1400px;
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
$screen-lg-max: ($screen-xl-min - 1);
$screen-xl-max: ($screen-xxl-min - 1);

$container-sm: 540px;
$container-md: 720px;
$container-lg: 960px;
$container-xl: 1140px;
$container-xxl: 1320px;

$ease-circ: cubic-bezier(0.11, 0.77, 0.28, 0.92);
$ease-expo: cubic-bezier(0.19, 1, 0.22, 1);
$red-alert: var(--Content-Color-Status-Red-Badge-bg, #db0b0b);
$yellow-alert: #ffce1f;
$highValueBorderColor: #db0b0b;
$mediumValueBorderColor: #b59216;
$highValueBGColor: #fde6e8;
$mediumValueBGColor: #fffae9;
$borderColor5: #e5e5ef;
$greenValueBorderColor: #488d68;
$greenValueBGColor: #f0f9f4;
$green-alert: #66c793;
$table-bg: #eceaea;
$light-gray: #838383;
$high-color: #db0b0b;
$medium-color: #ffce1f;
$normal-color: #66c793;
$high-bg: #feefef;
$medium-bg: #fffae9;
$normal-bg: #f0f9f4;

.patientRow {
  background: white;
  border: 3px solid $border-color1;
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
  overflow: auto;
}

.patientRowDiv {
  position: relative;
  background: white;
  padding: 5px;
  border-radius: 6px;
  margin-left: -5px;
  margin-right: -5px;
}

.patientRowInfo {
  background: $gray-bg;
  padding: 0.5rem;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  display: grid;
  width: 30%;
}

.ptName {
  font-family: $default-font;
  font-size: 16px;
  font-weight: $font-weight-bold;
  color: $text-color;
  margin-right: 0.625rem;
}

.ptRiskTitle {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-semibold;
  color: $text-color;
}

.ptStatusDiv {
  border-radius: 2px;
  border: 0.5px solid $border-color2;
  background: white;
  width: max-content;
  padding: 0rem 0.25rem;
  margin-right: 0.625rem;
  margin-top: 0.25rem;
}

.width50 {
  width: 50%;
}

.ptStatusActive {
  border-radius: 2px;
  border: 0.5px solid $green;
  background: white;
  width: max-content;
  padding: 0rem 0.25rem;
  margin-right: 0.625rem;
  margin-top: 0.25rem;
  user-select: none;
}

.ptStatusInactive {
  border-radius: 2px;
  border: 0.5px solid $red;
  background: white;
  width: max-content;
  padding: 0rem 0.25rem;
  margin-right: 0.625rem;
  margin-top: 0.25rem;
  user-select: none;
}

.ptText12 {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-medium;
  color: $text-color;
  width: max-content;
}

.ptStatusComplete {
  border-radius: 2px;
  border: 0.5px solid $complete;
  background: white;
  width: max-content;
  padding: 0rem 0.25rem;
  margin-right: 0.625rem;
  margin-top: 0.25rem;
  user-select: none;
}

.ptText12Complete {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-medium;
  color: $complete;
  width: max-content;
}

.ptText12Active {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-medium;
  color: $green;
  width: max-content;
}

.ptText12Inactive {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-medium;
  color: $red;
  width: max-content;
}

.displayGrid {
  display: grid;
}

.sideBorder {
  border-right: 1px solid $border-color2;
  border-left: 1px solid $border-color2;
}

.topBorder {
  border-top: 1px solid $border-color2;
}

.bgCallDis {
  background-color: $redbg;
}

.reviewDiv {
  display: flex;
  margin-top: 0.625rem;
  align-items: center;
  border-left: 1px solid $border-color4;
  padding-left: 0.5rem;
}

.checkDiv {
  height: 1rem;
  width: 1rem;
  background-color: white;
  border-radius: 0.25rem;
  border: 1px solid $blue;
}

.patientRowInf {
  min-width: 68px;
  margin: 6px 4px;
  border-radius: 3px;
  overflow: auto;
  padding: 0px;
  background: white;
  height: max-content;
  align-self: center;
}

.vitalsp {
  display: flex;
  align-items: flex-end;
  margin-top: -5px;
}

.borderBlack {
  border: 1px solid black;
}

.borderGrey {
  border: 1px solid $border-color2;
}

.borderDoted {
  border: 1px dashed black;
}

.notAllowed {
  cursor: not-allowed;
}

.borderGreen {
  border: 1px solid black;
}

.borderRed {
  border: 1px solid #f76363;
}

.borderYellow {
  border: 1px solid #ffce1f;
}

.actIc {
  height: 30px;
  width: 30px;
  object-fit: scale-down;
}

.borderTxt {
  border: 1px solid $text-color;
}

.resultSubLbl {
  font-family: $default-font;
  font-size: 10px;
  font-weight: $font-weight-medium;
  color: $text-color;
  text-align: left;
  margin-bottom: 1px;
}

.marginL2 {
  margin-left: 2px;
}

.patientTopTitle {
  font-family: $default-font;
  font-size: 14px;
  font-weight: $font-weight-bold;
  color: $text-color;
}

.resultLbl {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-medium;
  color: $text-color;
}

.rowInfoIc {
  height: 50px;
  text-align: center;
  place-content: center;
  display: flex;
}

.result-lbl {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-medium;
  color: $text-color;
}

.btnHvr:hover {
  opacity: 0.8;
  cursor: pointer;
}

.vcall {
  width: -webkit-fill-available;
}

.textComment {
  font-family: $default-font;
  font-size: 10px;
  font-weight: $font-weight-medium;
  color: $text-color;
  padding: 0.75rem;
  border: none;
  border-right: 1px solid $border-color2;
  resize: none;
  margin-right: 0.5rem;
}

.paddingIC {
  padding: 0.25em 0.5em;
}

.menuTitle {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-medium;
  color: $text-color;
}

.menuTitlePopup {
  font-family: $default-font;
  font-size: 14px;
  font-weight: $font-weight-medium;
  color: $text-color;
}

.riskLevelDivider {
  margin-top: 1.25rem;
}

.menuSbTitle {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-medium;
  color: $text-color;
  margin-right: 10px;
  margin-left: -3px;
}

.fullWidth {
  width: -webkit-fill-available;
}

.maxLength {
  overflow: hidden;
  text-align: left;
}

.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.menuOptionText {
  font-family: $default-font;
  font-size: 14px;
  font-weight: $font-weight-semibold;
  color: $blue;
  width: -webkit-fill-available;
  background: transparent;
  padding-left: 0px;
  text-align: left !important;
}

.borderTopBt {
  border-top: 2px solid $border-color2;
  border-bottom: 2px solid $border-color2;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0.5rem;
}

.borderRight {
  border-right: 1px solid $border-color4;
  margin-right: 10px;
}

.menuIc {
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
}

.menuIcRadioButton {
  height: 1.125rem;
  width: 1.125rem;
  margin-right: 0.5rem;
}

.bgWhite {
  background-color: white;
}

.war {
  position: absolute;
  margin-left: -15px;
}

.defaultBg {
  background-color: $default-bg;
}

.radioButton {
  background: transparent;
  padding: 0px;
  width: 35%;
}

.callWar {
  position: absolute;
  margin-left: -15px;
  margin-top: -8px;
}

.opachide {
  opacity: 0;
}

.buttons {
  display: flex;
  justify-content: flex-end;
}

.noBtn {
  font-family: $default-font;
  font-size: 14px;
  font-weight: $font-weight-semibold;
  background-color: white;
  color: $blue;
  border: 1px solid $blue;
  border-radius: 6px;
  min-width: 80px;
  margin-right: 20px;
}

.yesBtn {
  font-family: $default-font;
  font-size: 14px;
  font-weight: $font-weight-semibold;
  background-color: $blue;
  color: white;
  border: 1px solid $blue;
  border-radius: 6px;
  min-width: 80px;
}

.modalTitle {
  color: black;
  font-size: 14px;
  font-weight: $font-weight-bold;
}

.opac04 {
  opacity: 0.4;
}

.watchListIc {
  background-color: white;
  height: fit-content;
  align-self: center;
}

.optionsMenuPatient {
  min-width: 239px !important;
}

.patientListItem {
  width: 239px;
  padding-left: 12px !important;
  padding-bottom: 8px !important;
  margin-top: 0px !important;
  padding-top: 0 !important;
}

.riskLevelDiv {
  margin-bottom: 1rem;
}

.changeStatusTitle {
  padding: 0.25rem 0.5rem !important;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  border-radius: 6px !important;
}

.optionsHover {
  padding: 0.5rem 0px 0.5rem 0.5rem !important;
}
.optionsHover:hover {
  background-color: #ececff !important;
}
