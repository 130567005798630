.valid-input {
  border-color: green;
}

.invalid-input {
  border-color: red !important;
}

.invalid-input-feedback {
  color: red;
  font-size: 12px;
}
.md-height {
  height: 37px;
}
