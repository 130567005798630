.management-data {
  box-shadow: none;
  border-radius: 5px;
  padding: 1.2rem;
  padding-bottom: 0px;
}
.user-heading {
  font-size: 20px;
  color: #000000;
}
.select-organization {
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background: #eff3f6;
  padding: 10px 20px;
  font-size: 10px;
  height: auto;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 300px;
}
.user-management {
  margin-bottom: 100px;
}
.btn-glow-secondary {
  box-shadow: 0 0 7px 0 rgb(171 172 174), 0 0 10px 2px rgb(255 255 255);
}
.user-search {
  position: relative;
}
.user-search input {
  padding-left: 25px;
  /*width: 300px;*/
}
.user-search i {
  position: absolute;
  left: 7px;
  top: 50%;
  transform: translate(3px, -50%);
}
.user-search-input {
  width: 100%;
  padding: 2px 0px;
}
.search-inline {
  display: inline-block;
}
/* user management table */
.user-management-table th span {
  text-transform: uppercase;
}
.user-management-table tr th {
  line-height: 18px;
}
.user-management-table tr td {
  font-size: 10px;
}
.user-management-table tr th,
.user-management-table tr td {
  text-align: center;
}
.table-light thead .table-filter th {
  border-color: #e2e5e8;
}
.modal-content {
  border: 0;
}
.close:focus {
  outline: 0;
}
/*patient list*/
.user-management-table label {
  color: #171616;
}
.paitent-search {
  position: relative;
  width: 75%;
  display: inline-block;
}
.patient_sbutton {
  display: inline-block;
}
.paitent-search i {
  font-size: 16px;
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translate(10px, -50%);
}
.patient-check {
  background: #33cc00;
  color: #ffffff;
  font-size: 9px;
  border-radius: 50px;
  height: 16px;
  width: 16px;
  display: inline-block;
  line-height: 16px;
}
.patient-minus {
  background-color: #6c757d;
  color: #ffffff;
  font-size: 7px;
  border-radius: 50px;
  height: 13px;
  width: 13px;
  display: inline-block;
  line-height: 13px;
  box-shadow: 0px 0px 2px 1px #cecece;
}
.contact-check {
  font-size: 16px;
}
.mins-red {
  color: red;
}
.mins-orange {
  color: #ff9900;
}
.mins-green {
  color: #33cc00;
}
.patient-status {
  width: 12px;
  height: 12px;
  box-shadow: 0px 0px 2px 1px #cecece;
  border-radius: 50px;
  display: inline-block;
  border: 1px solid #b7b7b7;
}
.status-new {
  background: #6c757d;
  display: inline-block;
  padding: 5px;
  border-radius: 5px;
  height: 23px;
  line-height: 11px;
  color: #ffffff;
}
.status-white {
  background-color: white;
}
.status-black {
  background-color: #000000;
}
.status-red {
  background-color: red;
}
.status-orange {
  background-color: #ff9900;
}
.status-green {
  background-color: #33cc00;
}
.top-triangle {
  position: relative;
}
.top-triangle:before {
  position: absolute;
  content: "";
  border-top: 10px solid #ff9900;
  border-bottom: 10px solid transparent;
  border-right: 10px solid transparent;
  top: 0;
  left: -3px;
}
.alert-key {
  font-size: 13px;
}
.alert-status {
  background: #f6fafe;
  padding: 10px;
}
.status-alert-list {
  margin-bottom: 0;
}
.status-alert-list li {
  display: inline-block;
  width: 24%;
}
.status-alert-list li span {
  margin-right: 7px;
  vertical-align: middle;
}
.p_search_1 {
  display: flex;
}
.test_nowrap {
  white-space: nowrap;
}
/*custom select box*/
.scrollableList {
  width: 100%;
  padding: 2px 0px;
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(118, 118, 118);
  text-align: left;
}
.scrollableList ul {
  padding: 3px 0px 3px 0px;
  margin: 0;
  position: absolute;
  background: #ffffff;
  border: 1px solid #dedede;
}
.scrollableList ul li {
  list-style: none;
  cursor: default;
  line-height: 25px;
  font-weight: 100;
  padding-left: 5px;
  position: relative;
}
.scrollableList ul li:nth-child(1) .patient-status {
  display: none;
}
.scrollableList ul li:nth-child(2) .patient-status {
  background-color: #000000;
}
.scrollableList ul li:nth-child(3) .patient-status {
  background-color: red;
}
.scrollableList ul li:nth-child(4) .patient-status {
  background-color: #ff9900;
}
.scrollableList ul li:nth-child(5) .patient-status {
  background-color: #33cc00;
}
.status-dropdown {
  vertical-align: sub;
}
.selectedOption {
  position: relative;
  padding-left: 5px;
  font-weight: 100;
  white-space: nowrap;
  cursor: auto;
}
.selectedOption:before {
  position: absolute;
  content: "\f107";
  right: 0px;
  font-family: "FontAwesome";
  font-size: 16px;
  font-weight: 700;
  background: #fff;
  padding: 0px 2px;
  cursor: default;
}
.scrollableList ul li:hover {
  background: #007bff;
  color: #ffffff;
}

/*events*/
.events-title {
  font-weight: 600;
}
.patient-basic {
  font-size: 9px;
  font-style: italic;
}
.height-lbs {
  flex: 1 1 0%;
  text-align: right;
  white-space: nowrap;
  font-style: italic;
  font-weight: 600;
}

.patient_2_detials {
  padding: 10px;
}
.patient-header-link {
  color: #fff;
  display: block;
  text-align: left;
  padding: 7px 10px;
  font-size: 12px;
}
.patient-header-link:hover {
  color: #fff;
}

.pat_details {
  text-align: left;
  width: 40%;
  display: inline-block;
  margin-bottom: 5px;
}
.pat_opd {
  display: inline-block;
  margin-bottom: 5px;
}
/*patient event treatment tab css*/
.ability-manage {
  display: inline-block;
  width: 60%;
}
.ability-label {
  display: inline-block;
  width: 39%;
}

/*patient event accordation tab*/

.detail_accordation {
  background: #ffffff;
  border: 0;
  padding: 0;
  margin-bottom: 30px;
}
.detail_accordation .btn-header-link {
  text-align: left;
  color: #222;
  padding: 0;
}
.detail_accordation .btn-header-link:before {
  content: "\f106";
  font-family: "FontAwesome";
  font-weight: 900;
  float: left;
  font-size: 16px;
  margin-right: 10px;
}
.detail_accordation .btn-header-link.collapsed:before {
  content: "\f107";
}
.detail_accordation .collapse {
  border: 0;
}
.detail_accordation .collapse.show {
  color: #222;
}
.expand-all {
  position: relative;
}
.expand-all span:before {
  content: "Expand All";
  position: absolute;
  display: contents;
}
.expand-all.current span:before {
  content: "Collapse All";
  position: absolute;
  display: contents;
}
/*assign patient*/
.badge_item {
  line-height: 14px;
  font-size: 9px;
  font-weight: 500;
  color: #ffffff;
  background: #7b7b7b;
  padding: 4px;
}
.badge_item_single {
  line-height: 12px;
  font-size: 9px;
  font-weight: 500;
  color: #ffffff;
  background: #7b7b7b;
  width: 15px;
  height: 15px;
}
.clickable .assign-action {
  position: relative;
}
.clickable .assign-action:before {
  content: "\f106";
  font-family: "FontAwesome";
  position: absolute;
  font-weight: 900;
  left: 10px;
  font-size: 16px;
  top: 0;
}
.clickable.collapsed .assign-action:before {
  content: "\f107";
}
.dropdown-submenu {
  position: relative;
  transition: 0.3s ease-in-out;
}
.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
.multi-level {
  min-width: fit-content;
}
.multi-level a {
  color: #212529;
}
.multi-padding {
  padding: 2px 10px;
}
.dropdown-submenu .dropdown-menu {
  left: auto;
  top: 0;
  right: 100%;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
}

.input-change {
  position: absolute;
  top: -27px;
  width: 50px;
  left: 20px;
  background: #007bff12;
  border: 1px solid #1010103d;
  border-radius: 2px;
  padding: 2px 10px;
  font-weight: 600;
}
.vertical-change {
  font-size: 17px;
  writing-mode: vertical-lr;
  font-weight: 500;
  color: #007bff;
  margin-right: 20px;
}
.report-select-wrapper {
  position: absolute;
  left: 70%;
}
.report-select {
  width: 50%;
}

#inner-report-select {
  display: table;
  margin: 0 auto;
}

#outer-report-select {
  width: 100%;
}
