.editor-button {
  position: fixed;
}

.hide-edit-button {
  display: none;
}

.note-show-children {
  color: #fff;
  display: block;
  text-align: left;
  padding: 7px 10px;
  font-size: 12px;
}

.note-show-children.collapsed:after {
  content: "\f067";
}

.note-show-children:after {
  content: "\f068";
  font-family: "FontAwesome";
  font-weight: 900;
  float: right;
  color: #ffffff;
}

.note-filters {
  margin-top: 20px;
}
#note-multiselect .rs-picker-toggle {
  z-index: 0;
}
.custom-checkbox {
  z-index: 0 !important;
}
.note-content {
  width: 100%;
}
