$color-rca-blue: rgb(41, 83, 168);
$color-rca-blue-hover: rgb(22, 60, 137);
$color-rca-gray: rgb(173, 185, 202);
$color-rca-grayrow: rgb(228, 228, 228);
$color-readable-dark: #333;
$color-readable-dark-dim: #555;
$color-alert-default: #d0d0d0;
$color-alert-success: #5cbd6a;
$color-alert-warning: #dbab31;
$color-alert-danger: #e14d4d;
$color-alert-black: #000000;
$color-alert-gray: #bbbbbb;
$color-input: #fcfdfd;
$color-input-hover: #ffffff;
$border-color1: #70bfbf;
$border-color2: #e2e2e2;
$border-color3: #e8e7e7;
$border-color4: #888787;
$default-bg: #f5f5f5;
$gray-bg: #f8f8fe;
$text-color: #464646;
$green: #488d68;
$red: #db0b0b;
$redbg: #fde6e8;
$blue: #030575;
$complete: #0d74e3;

$font-primary: "Mada", sans-serif;
$font-body: "Poppins", "Arial", Helvetica, sans-serif;
$font-patient: "Libre Franklin", sans-serif;
$font-data: "Roboto", sans-serif;
$default-font: "Montserrat";

$font-size-sm: 12px;
$font-size-md: 14px;
$font-size-lg: 16px;
$font-size-xl: 18px;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$width-sidebar: 88px;
$height-input: 38px;
$height-label: 22px;
$box-shadow-button: 0 3px 7px 0 rgba(21, 21, 21, 0.21);
$border-radius-input: 6px;
$border-radius-lg: 9px;
$transition-duration-default: 300ms;
$transition-duration-input: 110ms;

$z-index-0: -100; /* Video */
$z-index-1: 100; /* Backgrounds */
$z-index-2: 200; /* Content, buttons */
$z-index-3: 300; /* Captions */
$z-index-4: 400; /* Meta */
$z-index-5: 500; /* Avatars, logos */
$z-index-6: 600; /* Sidebars, overlays */
$z-index-7: 700; /* Modals, transitions */
$z-index-8: 800; /* Navs */
$z-index-9: 900; /* Topbars, tooltips */
$z-index-10: 1000; /* Dev */

$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;
$screen-xxl-min: 1400px;
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
$screen-lg-max: ($screen-xl-min - 1);
$screen-xl-max: ($screen-xxl-min - 1);

$container-sm: 540px;
$container-md: 720px;
$container-lg: 960px;
$container-xl: 1140px;
$container-xxl: 1320px;

$ease-circ: cubic-bezier(0.11, 0.77, 0.28, 0.92);
$ease-expo: cubic-bezier(0.19, 1, 0.22, 1);
$red-alert: var(--Content-Color-Status-Red-Badge-bg, #db0b0b);
$yellow-alert: #ffce1f;
$highValueBorderColor: #db0b0b;
$mediumValueBorderColor: #b59216;
$highValueBGColor: #fde6e8;
$mediumValueBGColor: #fffae9;
$borderColor5: #e5e5ef;
$greenValueBorderColor: #488d68;
$greenValueBGColor: #f0f9f4;
$green-alert: #66c793;
$table-bg: #eceaea;
$light-gray: #838383;
$high-color: #db0b0b;
$medium-color: #ffce1f;
$normal-color: #66c793;
$high-bg: #feefef;
$medium-bg: #fffae9;
$normal-bg: #f0f9f4;

.top {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: $z-index-7;
}

.detailsContainer {
  :global {
    .css-tlfecz-indicatorContainer {
      color: rgb(204, 204, 204);
      display: flex;
      padding: 8px;
      transition: color 150ms ease 0s;
      box-sizing: border-box;
    }
    .css-tlfecz-indicatorContainer {
      color: rgb(204, 204, 204);
      display: flex;
      padding: 8px;
      transition: color 150ms ease 0s;
      box-sizing: border-box;
    }
    .css-8mmkcg {
      display: inline-block;
      fill: currentcolor;
      line-height: 1;
      stroke: currentcolor;
      stroke-width: 0;
    }
    .css-1rhbuit-multiValue {
      background-color: rgb(230, 230, 230);
      border-radius: 2px;
      display: flex;
      margin: 2px;
      min-width: 0px;
      box-sizing: border-box;
    }
    .css-xb97g8 {
      -webkit-box-align: center;
      align-items: center;
      border-radius: 2px;
      display: flex;
      padding-left: 4px;
      padding-right: 4px;
      box-sizing: border-box;
    }

    /* charts css */
    .highcharts-figure,
    .highcharts-data-table table {
      min-width: 360px;
      max-width: 800px;
      margin: 1em auto;
    }

    .highcharts-data-table table {
      font-family: Verdana, sans-serif;
      border-collapse: collapse;
      border: 1px solid #ebebeb;
      margin: 10px auto;
      text-align: center;
      width: 100%;
      max-width: 500px;
    }
    .highcharts-data-table caption {
      padding: 1em 0;
      font-size: 1.2em;
      color: #555;
      caption-side: top;
    }
    .highcharts-data-table th {
      font-weight: 600;
      padding: 0.5em;
      color: #363636;
    }
    .highcharts-data-table td,
    .highcharts-data-table th,
    .highcharts-data-table caption {
      padding: 0.5em;
    }
    .highcharts-data-table thead tr,
    .highcharts-data-table tr:nth-child(even) {
      background: #f8f8f8;
    }
    .highcharts-data-table tr:hover {
      background: #f1f7ff;
    }
    .charts-area {
      padding: 1px;
      border-radius: 5px;
      box-shadow: 1px 1px 3px 2px #e4e4e4;
      margin-bottom: 20px;
    }

    .input-change {
      position: absolute;
      top: -27px;
      width: 50px;
      left: 20px;
      background: #007bff12;
      border: 1px solid #1010103d;
      border-radius: 2px;
      padding: 2px 10px;
      font-weight: 600;
    }
    .vertical-change {
      font-size: 17px;
      writing-mode: vertical-lr;
      font-weight: 500;
      color: #007bff;
      margin-right: 20px;
    }
    .chart-hr {
      border-width: 2px;
      border-color: #a9a9a9ff;
    }
    .hide-data-table {
      display: none;
    }

    .header-content {
      display: flex !important;
      flex-wrap: wrap;
      text-align: center;
      align-content: center;
      justify-content: space-between;
    }

    a:active {
      color: white !important;
      text-decoration: none !important;
    }
    .detail-table td {
      text-align: left !important;
    }

    .highcharts-contextmenu hr {
      margin-top: 5px !important;
      margin-bottom: 5px !important;
      border: 0;
      border-top: 1px solid #e5e5ea;
    }

    .table-responsive {
      overflow-x: visible !important;
    }
    .pos-relative {
      position: relative;
    }
    .cmt-btn {
      position: absolute;
      right: 0%;
      top: 1.8%;
    }
    .react-datepicker-popper {
      z-index: $z-index-7 !important;
    }

    .font-arial {
      font-family: Arial, Helvetica, sans-serif;
    }

    .font-verdana {
      font-family: Verdana, Geneva, Tahoma, sans-serif;
    }

    .font-14 {
      font-size: 14px;
    }

    .font-bold {
      font-weight: 500;
    }

    .patient-header-link {
      font-size: 14px !important;
    }

    .select-organization {
      font-size: 14px !important;
    }

    .table-responsive {
      overflow-x: visible !important;
      background: transparent;
    }

    .rs-btn-sm {
      margin-right: 0px !important;
      font-weight: 500 !important;
      font-size: 14px !important;
    }

    .form-control {
      font-size: 14px;
    }

    .btn-glow-primary {
      font-size: 14px !important;
      font-weight: 500 !important;
    }

    .add-user span {
      font-size: 14px !important;
    }

    .bg-white {
      background-color: white !important;
    }
  }
}

.tabContent {
  padding: 1.25rem;
  background: #fff;
  overflow-x: visible;
  overflow-y: visible;
}

.navigateBackDiv {
  width: 15px;
  margin-right: 10px;
  margin-top: 15px;
}

.navigateBackIcon {
  cursor: pointer;
}
