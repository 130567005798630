$color-rca-blue: rgb(41, 83, 168);
$color-rca-blue-hover: rgb(22, 60, 137);
$color-rca-gray: rgb(173, 185, 202);
$color-rca-grayrow: rgb(228, 228, 228);
$color-readable-dark: #333;
$color-readable-dark-dim: #555;
$color-alert-default: #d0d0d0;
$color-alert-success: #5cbd6a;
$color-alert-warning: #dbab31;
$color-alert-danger: #e14d4d;
$color-alert-black: #000000;
$color-alert-gray: #bbbbbb;
$color-input: #fcfdfd;
$color-input-hover: #ffffff;
$border-color1: #70bfbf;
$border-color2: #e2e2e2;
$border-color3: #e8e7e7;
$border-color4: #888787;
$default-bg: #f5f5f5;
$gray-bg: #f8f8fe;
$text-color: #464646;
$green: #488d68;
$red: #db0b0b;
$redbg: #fde6e8;
$blue: #030575;
$complete: #0d74e3;

$font-primary: "Mada", sans-serif;
$font-body: "Poppins", "Arial", Helvetica, sans-serif;
$font-patient: "Libre Franklin", sans-serif;
$font-data: "Roboto", sans-serif;
$default-font: "Montserrat";

$font-size-sm: 12px;
$font-size-md: 14px;
$font-size-lg: 16px;
$font-size-xl: 18px;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$width-sidebar: 88px;
$height-input: 38px;
$height-label: 22px;
$box-shadow-button: 0 3px 7px 0 rgba(21, 21, 21, 0.21);
$border-radius-input: 6px;
$border-radius-lg: 9px;
$transition-duration-default: 300ms;
$transition-duration-input: 110ms;

$z-index-0: -100; /* Video */
$z-index-1: 100; /* Backgrounds */
$z-index-2: 200; /* Content, buttons */
$z-index-3: 300; /* Captions */
$z-index-4: 400; /* Meta */
$z-index-5: 500; /* Avatars, logos */
$z-index-6: 600; /* Sidebars, overlays */
$z-index-7: 700; /* Modals, transitions */
$z-index-8: 800; /* Navs */
$z-index-9: 900; /* Topbars, tooltips */
$z-index-10: 1000; /* Dev */

$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;
$screen-xxl-min: 1400px;
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
$screen-lg-max: ($screen-xl-min - 1);
$screen-xl-max: ($screen-xxl-min - 1);

$container-sm: 540px;
$container-md: 720px;
$container-lg: 960px;
$container-xl: 1140px;
$container-xxl: 1320px;

$ease-circ: cubic-bezier(0.11, 0.77, 0.28, 0.92);
$ease-expo: cubic-bezier(0.19, 1, 0.22, 1);
$red-alert: var(--Content-Color-Status-Red-Badge-bg, #db0b0b);
$yellow-alert: #ffce1f;
$highValueBorderColor: #db0b0b;
$mediumValueBorderColor: #b59216;
$highValueBGColor: #fde6e8;
$mediumValueBGColor: #fffae9;
$borderColor5: #e5e5ef;
$greenValueBorderColor: #488d68;
$greenValueBGColor: #f0f9f4;
$green-alert: #66c793;
$table-bg: #eceaea;
$light-gray: #838383;
$high-color: #db0b0b;
$medium-color: #ffce1f;
$normal-color: #66c793;
$high-bg: #feefef;
$medium-bg: #fffae9;
$normal-bg: #f0f9f4;

.rowAlert {
  // display: flex;
  padding: 0.625rem 1.25rem;
  background: $default-bg;
  margin-bottom: 0.625rem;
  border-radius: 0.375rem;
}
.dataRow {
  display: flex;
}
.selectAll {
  display: flex;
  padding: 0.625rem 0.5rem;
  cursor: pointer;
}

.selectAllText {
  font-size: 0.75rem;
  font-family: "Montserrat";
  font-weight: 500;
  color: $text-color;
}

.alertFont {
  font-size: 0.75rem;
  font-family: "Montserrat";
  font-weight: 500;
  color: $text-color;
}

.dateFontAlert {
  font-size: 0.625rem;
  font-family: "Montserrat";
  font-weight: 500;
  color: $text-color;
  vertical-align: middle;
  display: flex;
  align-items: center;
  width: 9.25rem;
  align-self: center;
}

.dateFont {
  font-size: 0.625rem;
  font-family: "Montserrat";
  font-weight: 500;
  color: $text-color;
  vertical-align: middle;
  display: flex;
  align-items: center;
}

.alertTextEllipsis {
  width: 9.9375rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.commentDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}
.severityScore {
  height: 1.25rem;
  width: 3.75rem;
  font-size: 0.75rem;
  font-family: "Montserrat";
  font-weight: 500;
  border-radius: 0.125rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
}

.redAlert {
  background: $red-alert;
}

.yellowAlert {
  background: $yellow-alert;
}

.greenAlert {
  background: $green-alert;
}

.rejectButton {
  padding: 0.5rem 1rem 0.5rem 1rem;
  gap: 0.5rem;
  border-radius: 0.375rem;
  font-family: "Montserrat";
  font-size: 0.875rem;
  font-weight: 600;
  color: $blue;
  border: 0.0625rem solid $blue;
  width: 5.1875rem;
}

.acceptButton {
  padding: 0.5rem 1rem 0.5rem 1rem;
  gap: 0.5rem;
  border-radius: 0.375rem;
  font-family: "Montserrat";
  font-size: 0.875rem;
  font-weight: 600;
  background: $blue;
  color: white;
  width: 5.1875rem;
  margin-left: 1.25rem;
}

.actionAlert {
  display: flex;
  margin: 1.25rem 1.25rem 1.25rem 0.5rem;
  bottom: 0;
}

.checkBoxAlert {
  cursor: pointer;
  margin-right: 0.25rem;
  width: 1rem;
  height: 1rem;
}

.checkBoxAlertData {
  cursor: pointer;
  margin-right: 0.25rem;
  justify-content: flex-start;
  align-self: flex-start;
  display: flex;
  margin-top: 0.75rem;
  width: 1rem !important;
  height: 1rem !important;
}

.notAllowedClick {
  cursor: not-allowed;
  opacity: 0.4;
}

.cursorPointer {
  cursor: pointer;
}

.alertDataDiv {
  overflow-y: auto;
  height: calc(100vh - 27rem);
  padding-left: 0rem !important;
  min-width: fit-content;
}

.alertDataDiv:focus {
  outline: none !important;
}

.systolicDiv {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.bpValueDiv {
  width: 2.375rem;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 0.6875rem;
  margin-top: 0.25rem;
}

.highValueBG {
  border: 0.03125rem solid $highValueBorderColor;
  background-color: $highValueBGColor;
}

.mediumValueBG {
  border: 0.03125rem solid $mediumValueBorderColor;
  background-color: $mediumValueBGColor;
}

.valueDiv {
  display: flex;
  justify-content: space-between;
}

.bpLabel {
  font-family: "Montserrat";
  font-size: 0.75rem;
  font-weight: 600;
  color: $text-color;
  display: flex;
  align-items: center;
  justify-content: center;
}

.highAlertText {
  color: white;
}

.greenAlertText {
  color: $text-color;
}

.unit {
  font-family: "Montserrat";
  font-size: 0.75rem;
  font-weight: 600;
  color: $text-color;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.25rem;
  margin-left: 0.25rem;
}

.maxLimitBP {
  font-family: "Montserrat";
  font-size: 0.75rem;
  font-weight: 500;
  color: $text-color;
  text-align: center;
  align-items: center;
  display: flex;
}

.seperatorDiv {
  height: 0.125rem;
  background-color: $border-color2;
  margin: 0.5rem 0rem;
}

.glucoseValueDiv {
  display: flex;
}

/****************************************/

.dateTimeDesc {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-medium;
  color: $light-gray;
}

.dateTime {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-medium;
  color: $text-color;
}

.tableRowText {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-medium;
  color: $text-color;
}

.typeTitle {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-bold;
  color: $text-color;
}

.typeDateText {
  font-family: $default-font;
  font-size: 10px;
  font-weight: $font-weight-medium;
  color: $text-color;
  width: max-content;
}

.margintp {
  margin-top: 1px;
}

.typeDatelbl {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-semibold;
  color: $text-color;
  margin-left: 4px;
}

.typeDiv {
  padding: 0.59375rem 0rem 0rem 0rem;
  display: flex;
  justify-content: space-between;
  width: 24vw;
  border-radius: 6px;
}
.mainTypeDiv {
  background: $default-bg;
  padding: 0rem 0px 0.59375rem 0.5rem;
  display: flex;
  border-radius: 6px;
  margin-bottom: 0.5rem;
  min-height: 2.5rem;
  width: 26vw;
}
.typeDataDiv {
  display: flex;
  align-items: center;
  height: fit-content;
  margin-right: 0.2rem;
}
.alertDataMainDiv {
  min-width: 26rem;
}
.highText {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-medium;
  color: white;
  background: $high-color;
  border-radius: 2px;
  min-width: 60px;
  text-align-last: center;
  margin: 0px 4px;
  height: 1.3125rem;
  align-items: center;
  display: flex;
  justify-content: center;
}

.normalText {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-medium;
  color: $text-color;
  background: $normal-color;
  border-radius: 2px;
  min-width: 60px;
  text-align-last: center;
  margin: 0px 4px;
  height: 1.3125rem;
  align-items: center;
  display: flex;
  justify-content: center;
}

.mediumText {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-medium;
  color: $text-color;
  background: $medium-color;
  border-radius: 2px;
  min-width: 60px;
  text-align-last: center;
  margin: 0px 4px;
  height: 1.3125rem;
  align-items: center;
  display: flex;
  justify-content: center;
}

.highValue {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-semibold;
  color: $text-color;
  background: $high-bg;
  border: 0.5px solid $high-color;
  border-radius: 11px;
  min-width: 38px;
  text-align-last: center;
  margin-right: 4px;
  height: 1.25rem;
  display: flex;
  text-align: center;
  justify-content: center;
  align-self: center;
  align-items: center;
}

.mediumValue {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-semibold;
  color: $text-color;
  background: $medium-bg;
  border: 0.5px solid #b59216;
  border-radius: 11px;
  min-width: 38px;
  text-align-last: center;
  margin-right: 4px;
  height: 1.25rem;
  display: flex;
  text-align: center;
  justify-content: center;
  align-self: center;
  align-items: center;
}

.normalValue {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-semibold;
  color: $text-color;
  background: #f0f9f4;
  border: 0.5px solid #488d68;
  border-radius: 11px;
  min-width: 38px;
  text-align-last: center;
  margin-right: 4px;
  height: 1.25rem;
  display: flex;
  text-align: center;
  justify-content: center;
  align-self: center;
  align-items: center;
}

.lowValue {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-semibold;
  color: $text-color;
  background: #e6e6e6;
  border-radius: 11px;
  min-width: 38px;
  text-align-last: center;
  margin-right: 4px;
  height: 1.25rem;
  display: flex;
  text-align: center;
  justify-content: center;
  align-self: center;
  align-items: center;
}

.operatorTxt {
  font-family: $default-font;
  font-size: 14px;
  font-weight: $font-weight-bold;
  color: $text-color;
  margin-right: 4px;
  min-width: 15px;
  text-align-last: center;
}

.maxWidth {
  width: max-content;
}

.typeParentDiv {
  height: fit-content;
}
.typeParentDivCheckBox {
  height: fit-content;
}
