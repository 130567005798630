$color-rca-blue: rgb(41, 83, 168);
$color-rca-blue-hover: rgb(22, 60, 137);
$color-rca-gray: rgb(173, 185, 202);
$color-rca-grayrow: rgb(228, 228, 228);
$color-readable-dark: #333;
$color-readable-dark-dim: #555;
$color-alert-default: #d0d0d0;
$color-alert-success: #5cbd6a;
$color-alert-warning: #dbab31;
$color-alert-danger: #e14d4d;
$color-alert-black: #000000;
$color-alert-gray: #bbbbbb;
$color-input: #fcfdfd;
$color-input-hover: #ffffff;
$border-color1: #70bfbf;
$border-color2: #e2e2e2;
$border-color3: #e8e7e7;
$border-color4: #888787;
$default-bg: #f5f5f5;
$gray-bg: #f8f8fe;
$text-color: #464646;
$green: #488d68;
$red: #db0b0b;
$redbg: #fde6e8;
$blue: #030575;
$complete: #0d74e3;

$font-primary: "Mada", sans-serif;
$font-body: "Poppins", "Arial", Helvetica, sans-serif;
$font-patient: "Libre Franklin", sans-serif;
$font-data: "Roboto", sans-serif;
$default-font: "Montserrat";

$font-size-sm: 12px;
$font-size-md: 14px;
$font-size-lg: 16px;
$font-size-xl: 18px;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$width-sidebar: 88px;
$height-input: 38px;
$height-label: 22px;
$box-shadow-button: 0 3px 7px 0 rgba(21, 21, 21, 0.21);
$border-radius-input: 6px;
$border-radius-lg: 9px;
$transition-duration-default: 300ms;
$transition-duration-input: 110ms;

$z-index-0: -100; /* Video */
$z-index-1: 100; /* Backgrounds */
$z-index-2: 200; /* Content, buttons */
$z-index-3: 300; /* Captions */
$z-index-4: 400; /* Meta */
$z-index-5: 500; /* Avatars, logos */
$z-index-6: 600; /* Sidebars, overlays */
$z-index-7: 700; /* Modals, transitions */
$z-index-8: 800; /* Navs */
$z-index-9: 900; /* Topbars, tooltips */
$z-index-10: 1000; /* Dev */

$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;
$screen-xxl-min: 1400px;
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
$screen-lg-max: ($screen-xl-min - 1);
$screen-xl-max: ($screen-xxl-min - 1);

$container-sm: 540px;
$container-md: 720px;
$container-lg: 960px;
$container-xl: 1140px;
$container-xxl: 1320px;

$ease-circ: cubic-bezier(0.11, 0.77, 0.28, 0.92);
$ease-expo: cubic-bezier(0.19, 1, 0.22, 1);
$red-alert: var(--Content-Color-Status-Red-Badge-bg, #db0b0b);
$yellow-alert: #ffce1f;
$highValueBorderColor: #db0b0b;
$mediumValueBorderColor: #b59216;
$highValueBGColor: #fde6e8;
$mediumValueBGColor: #fffae9;
$borderColor5: #e5e5ef;
$greenValueBorderColor: #488d68;
$greenValueBGColor: #f0f9f4;
$green-alert: #66c793;
$table-bg: #eceaea;
$light-gray: #838383;
$high-color: #db0b0b;
$medium-color: #ffce1f;
$normal-color: #66c793;
$high-bg: #feefef;
$medium-bg: #fffae9;
$normal-bg: #f0f9f4;

.navigateBackContainer {
  width: fit-content !important;
  justify-content: center;
  align-items: center;
  display: flex;
}

.pointer {
  cursor: pointer;
}

.createNoteMainDiv {
  padding: 1rem 1rem 0rem 1rem;
  height: 100vh;
}
.headerPage {
  font-family: "Montserrat";
  font-size: 1rem;
  font-weight: 700;
  text-align: left;
  color: $text-color;
}

.detailsDiv {
  margin-top: 2rem;
  display: flex;
  // justify-content: space-between;
}

.optionHeader {
  font-family: "Montserrat";
  font-size: 0.75rem;
  font-weight: 600;
  color: $text-color;
}

.acceptAlertDiv {
  width: 40vw;
}

.acceptAlertDiv:focus {
  outline: none !important;
}

.acceptAlertContentDiv {
  height: calc(82vh - 4.5rem);
  overflow: auto;
  min-width: 27.5rem;
}

.acceptAlertContentDiv:focus {
  outline: none !important;
}

.interventionDiv {
  width: 60vw;
  padding: 1rem 1.25rem;
  background: var(--Background-Background-Secondary, #f5f5f5);
  margin-left: 2rem;
  border-radius: 0.375rem;
  max-height: calc(100vh - 9rem);
}
.interventionMainDiv {
  height: calc(100vh - 4.5rem);
}

.subTitle {
  font-family: "MontSerrat";
  color: #464646;
  font-weight: 700;
  font-size: 0.875rem;
  vertical-align: top;
}

.acceptAlertLabelPadding {
  padding: 1rem 0rem 1rem 0.5rem;
}
.rowAlert {
  display: flex;
  padding: 0.625rem 1.25rem;
  background: var(--Background-Background-Secondary, #f5f5f5);
  margin-bottom: 0.625rem;
  border-radius: 0.375rem;
  align-items: center;
  vertical-align: middle;
}

.alertFont {
  font-size: 0.75rem;
  font-family: "Montserrat";
  font-weight: 500;
  color: $text-color;
  padding-left: 0.4375rem;
  vertical-align: middle;
  display: flex;
  align-items: center;
}

.dateDiv {
  width: 45%;
  display: flex;
}

.dateFontAlert {
  font-size: 0.625rem;
  font-family: "Montserrat";
  font-weight: 500;
  color: $text-color;
  vertical-align: middle;
  display: flex;
  align-items: center;
  // width: 9.25rem;
  align-self: center;
  width: max-content;
}

.alertTextEllipsis {
  // width: 3rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cancelActionButton {
  padding: 0.5rem 1rem 0.5rem 1rem;
  gap: 0.5rem;
  border-radius: 0.375rem;
  font-family: "Montserrat";
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--Content-Color-Accent, #030575);
  border: 0.0625rem solid var(--Border-Button-Outline, #030575);
  width: 5.125rem;
  height: 2.5rem;
}

.createActionButton {
  padding: 0.5rem 1rem 0.5rem 1rem;
  gap: 0.5rem;
  border-radius: 0.375rem;
  font-family: "Montserrat";
  font-size: 0.875rem;
  font-weight: 600;
  background: var(--Content-Color-Accent, #030575);
  color: #ffffff;
  width: 5.125rem;
  margin-left: 1.25rem;
}

.cursorPointer {
  cursor: pointer;
}

.actionAlert {
  display: flex;
  margin: 0.875rem 0rem 0rem 1.25rem;
  position: absolute;
  bottom: 0.875rem;
  right: 1.25rem;
}

.imgCancelAlert {
  // margin-left: 1rem;
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;
  margin: 0.5rem 0rem 0rem 0rem;
}

.narrativeTextArea {
  background-color: white;
  border-radius: 0.25rem;
  min-height: 4.75rem;
  margin-top: 0.5rem;
  padding: 0.25rem 0.625rem 0.25rem 0.625rem;
  display: flex;
  // align-items: center;
  textarea {
    font-family: "Montserrat";
    resize: vertical;
    border: none;
    width: 100%;
    max-height: calc(100vh - 20rem);
    border-radius: 0.25rem;
    color: #464646;
    font-size: 0.75rem;
    min-height: 1rem;
  }

  textarea:focus {
    outline: none !important;
  }
}
.dividerDiv {
  border-bottom: 0.0625rem solid #e2e2e2;
  margin: 1.0625rem 0rem;
  width: 100%;
}

.radioDiv {
  flex-direction: row !important;
  width: 100%;
  margin-left: 0.625rem !important;
}

.radioSummary {
  display: contents !important;
  width: 100%;
}

.radioButtonText {
  font-family: "Montserrat" !important;
  font-size: 0.875rem !important;
  color: $text-color !important;
  margin-right: 1.25rem !important;
}

//

.alertDataDiv {
  overflow-y: auto;
  height: 41vh;
}

.alertDataDiv:focus {
  outline: none !important;
}

.systolicDiv {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.bpValueDiv {
  width: 2.375rem;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 0.6875rem;
  margin-top: 0.25rem;
}

.highValueBG {
  border: 0.03125rem solid $highValueBorderColor;
  background-color: $highValueBGColor;
}

.mediumValueBG {
  border: 0.03125rem solid $mediumValueBorderColor;
  background-color: $mediumValueBGColor;
}

.valueDiv {
  display: flex;
  justify-content: space-between;
}

.bpLabel {
  font-family: "Montserrat";
  font-size: 0.75rem;
  font-weight: 600;
  color: $text-color;
  display: flex;
  align-items: center;
  justify-content: center;
}

.maxLimitBP {
  font-family: "Montserrat";
  font-size: 0.75rem;
  font-weight: 500;
  color: $text-color;
  text-align: center;
  align-items: center;
  display: flex;
}

.seperatorDiv {
  height: 0.125rem;
  background-color: $border-color2;
  margin: 0.5rem 0rem;
}
.dataRow {
  display: flex;
  align-items: center;
  vertical-align: middle;
  justify-content: space-between;
  width: 23rem;
}
.alertLevelDiv {
  margin-left: 0.5rem;
}
.observationDiv {
  margin-right: 1.5rem;
}

.glucoseValueDiv {
  display: flex;
}

.dropDownDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0.05rem solid $borderColor5;
  border-radius: 0.25rem;
  width: 23.90625rem;
  margin-top: 0.5em;
  background: white;
  height: 2.25em;
  padding-right: 1rem;
  cursor: pointer;
}

.dropDownLbl {
  font-family: "Montserrat";
  font-size: 0.75rem;
  font-weight: 500;
  color: $text-color;
  margin: 0.5rem 0.5rem 0.5rem 0.625rem;
  width: 100%;
}
.dropDownLblOpac {
  font-family: "Montserrat";
  font-size: 0.75rem;
  font-weight: 500;
  color: #838383;
  margin: 0.5rem 0.5rem 0.5rem 0.625rem;
  width: 100%;
}
.arrowImg {
  cursor: pointer;
}
.interventDiv {
  display: flex;
  justify-content: space-between;
}

.interventionsDropDiv {
  // width: 45%;
}
.narrativeTextAreaDiv {
  // width: 45%;
  width: 23.90625rem;
}

.switchClass {
  margin-left: 0.625rem;
  cursor: pointer;
}

.switchDiv {
  display: flex;
  align-items: center;
}

.directPatientContactDiv {
  text-align: center;
  align-items: center;
  display: flex;
}

.multiDropS {
  position: absolute;
  width: 23.90625rem;
  background-color: white;
  padding: 0.625rem;
  z-index: 2;
  border-radius: 0.375rem;
  box-shadow: 0px 4px 10px 0px #01023166;
  height: 20.0625rem;
}

.multiDropS:focus {
  outline: none !important;
}

.dropDownDivs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0.05rem solid $borderColor5;
  border-radius: 0.25rem;
  width: 23.90625rem;
  margin-top: 0.5em;
  background: white;
  height: 2.25em;
}

.dropDownLabel {
  font-family: "Montserrat";
  font-size: 0.75rem;
  font-weight: 500;
  color: $text-color;
  margin: 0.5rem;
}

.dropDownLabels {
  font-family: "Montserrat";
  font-size: 0.75rem;
  font-weight: 500;
  color: $text-color;
  padding-left: 0.5rem;
  cursor: pointer;
}

.dropDownLabels:hover {
  opacity: 0.9;
  cursor: pointer;
}

.optionMenu {
  display: flex;
  align-items: center;
  padding: 0.25rem 0rem;
}

.checkIcon {
  cursor: pointer;
}

.unCheckIcon {
  cursor: pointer;
}

.interDiv {
  margin-top: 1.25rem;
  max-height: calc(100vh - 20rem);
  overflow-y: auto;
  width: 24rem;
  display: ruby;
}

.interDiv:focus {
  outline: none !important;
}

.searchDiv {
  width: 22.7rem;
  border: 0.0625rem solid $border-color2;
  border-radius: 0.25rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  input {
    border: none;
    outline: none;
    font-family: "Montserrat";
    font-size: 0.75rem;
    font-weight: 500;
    color: $text-color;
    width: 20.7rem;
    display: flex;
    align-items: center;
  }
}
.optionsList {
  overflow: auto;
  max-height: 13.0625rem;
}

.optionsList:focus {
  outline: none !important;
}

.optionsListSearchDisabled {
  overflow: auto;
  max-height: 16rem;
}

.optionsListSearchDisabled:focus {
  outline: none !important;
}

.doneActionButton {
  height: 2.5rem;
  width: 5.125rem;
  background-color: $blue;
  color: white;
  font-family: "Montserrat";
  font-size: 0.875rem;
  font-weight: 600;
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
}

.doneActionButton:hover {
  opacity: 0.8;
  cursor: pointer;
}

.doneActionButtonDisabled {
  height: 2.5rem;
  width: 5.125rem;
  background-color: $blue;
  color: white;
  font-family: "Montserrat";
  font-size: 0.875rem;
  font-weight: 600;
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
  cursor: not-allowed;
  opacity: 0.4;
}

.doneActionButtonDisabled:hover {
  opacity: 0.4 !important;
  cursor: not-allowed !important;
}

.actionDiv {
  float: right;
}

.actionDivDisabled {
  float: right;
  cursor: not-allowed;
}

.disableAction {
  pointer-events: none;
  opacity: 0.4;
  cursor: not-allowed;
}

.mainDropDiv {
}

.dispoDiv {
  display: flex;
  align-items: center;
  width: 24rem;
}

.directContactDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.directPatientContactDiv {
  width: 24rem;
}

.alertEmpty {
  // width: 27.125rem;
  height: calc(82vh - 4.5rem);
  background: var(--Background-Background-Secondary, #f5f5f5);
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 0.375rem;
}

.alertEmptyImg {
  display: flex;
  justify-content: center;
  align-items: center;
}
.emptyAlertText {
  font-family: "Montserrat";
  font-size: 0.875em;
  font-weight: 500;
  color: $text-color;
}

.actionNotAllowed {
  cursor: not-allowed;
  opacity: 0.4;
}

.actionNotAllowed:hover {
  cursor: not-allowed;
  opacity: 0.4;
}

.valueComponent {
  display: flex;
  justify-content: center;
  align-items: center;
}

/****************************************/

.dateTimeDesc {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-medium;
  color: $light-gray;
}

.dateTime {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-medium;
  color: $text-color;
}

.tableRowText {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-medium;
  color: $text-color;
}

.typeTitle {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-bold;
  color: $text-color;
}

.typeDateText {
  font-family: $default-font;
  font-size: 10px;
  font-weight: $font-weight-medium;
  color: $text-color;
  width: max-content;
}

.margintp {
  margin-top: 1px;
}

.typeDatelbl {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-semibold;
  color: $text-color;
  margin-left: 4px;
}

.typeDiv {
  padding: 0.59375rem 0rem 0rem 0rem;
  display: flex;
  justify-content: space-between;
  // width: 24rem;
  border-radius: 6px;
}
.mainTypeDiv {
  background: $default-bg;
  padding: 0rem 0.5rem 0rem 0.5rem;
  display: flex;
  border-radius: 6px;
  margin-bottom: 0.5rem;
  min-height: 2.5rem;
  width: 100%;
}
.typeDataDiv {
  display: flex;
  align-items: center;
  height: fit-content;
}

.highText {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-medium;
  color: white;
  background: $high-color;
  border-radius: 2px;
  min-width: 60px;
  text-align-last: center;
  margin: 0px 4px;
  height: 1.3125rem;
  align-items: center;
  display: flex;
  justify-content: center;
}

.normalText {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-medium;
  color: $text-color;
  background: $normal-color;
  border-radius: 2px;
  min-width: 60px;
  text-align-last: center;
  margin: 0px 4px;
  height: 1.3125rem;
  align-items: center;
  display: flex;
  justify-content: center;
}

.mediumText {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-medium;
  color: $text-color;
  background: $medium-color;
  border-radius: 2px;
  min-width: 60px;
  text-align-last: center;
  margin: 0px 4px;
  height: 1.3125rem;
  align-items: center;
  display: flex;
  justify-content: center;
}

.highValue {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-semibold;
  color: $text-color;
  background: $high-bg;
  border: 0.5px solid $high-color;
  border-radius: 11px;
  min-width: 38px;
  text-align-last: center;
  margin-right: 4px;
  height: 1.25rem;
  display: flex;
  text-align: center;
  justify-content: center;
  align-self: center;
  align-items: center;
}

.mediumValue {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-semibold;
  color: $text-color;
  background: $medium-bg;
  border: 0.5px solid #b59216;
  border-radius: 11px;
  min-width: 38px;
  text-align-last: center;
  margin-right: 4px;
  height: 1.25rem;
  display: flex;
  text-align: center;
  justify-content: center;
  align-self: center;
  align-items: center;
}

.normalValue {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-semibold;
  color: $text-color;
  background: #f0f9f4;
  border: 0.5px solid #488d68;
  border-radius: 11px;
  min-width: 38px;
  text-align-last: center;
  margin-right: 4px;
  height: 1.25rem;
  display: flex;
  text-align: center;
  justify-content: center;
  align-self: center;
  align-items: center;
}

.lowValue {
  font-family: $default-font;
  font-size: 12px;
  font-weight: $font-weight-semibold;
  color: $text-color;
  background: #e6e6e6;
  border-radius: 11px;
  min-width: 38px;
  text-align-last: center;
  margin-right: 4px;
  height: 1.25rem;
  display: flex;
  text-align: center;
  justify-content: center;
  align-self: center;
  align-items: center;
}

.operatorTxt {
  font-family: $default-font;
  font-size: 14px;
  font-weight: $font-weight-bold;
  color: $text-color;
  margin-right: 4px;
  min-width: 15px;
  text-align-last: center;
}

.maxWidth {
  width: max-content;
}

.typeParentDiv {
  height: fit-content;
  width: 100%;
  padding-bottom: 0.5rem;
}
.typeParentDivCheckBox {
  height: fit-content;
}

.userName {
  //styleName: H6Medium;
  font-family: "Montserrat";
  font-size: 1rem;
  font-weight: 500;
  color: $text-color;
}
.clearDisabled {
  cursor: not-allowed;
  opacity: 0.4;
}
