$color-rca-blue: rgb(41, 83, 168);
$color-rca-blue-hover: rgb(22, 60, 137);
$color-rca-gray: rgb(173, 185, 202);
$color-rca-grayrow: rgb(228, 228, 228);
$color-readable-dark: #333;
$color-readable-dark-dim: #555;
$color-alert-default: #d0d0d0;
$color-alert-success: #5cbd6a;
$color-alert-warning: #dbab31;
$color-alert-danger: #e14d4d;
$color-alert-black: #000000;
$color-alert-gray: #bbbbbb;
$color-input: #fcfdfd;
$color-input-hover: #ffffff;
$border-color1: #70bfbf;
$border-color2: #e2e2e2;
$border-color3: #e8e7e7;
$border-color4: #888787;
$default-bg: #f5f5f5;
$gray-bg: #f8f8fe;
$text-color: #464646;
$green: #488d68;
$red: #db0b0b;
$redbg: #fde6e8;
$blue: #030575;
$complete: #0d74e3;

$font-primary: "Mada", sans-serif;
$font-body: "Poppins", "Arial", Helvetica, sans-serif;
$font-patient: "Libre Franklin", sans-serif;
$font-data: "Roboto", sans-serif;
$default-font: "Montserrat";

$font-size-sm: 12px;
$font-size-md: 14px;
$font-size-lg: 16px;
$font-size-xl: 18px;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$width-sidebar: 88px;
$height-input: 38px;
$height-label: 22px;
$box-shadow-button: 0 3px 7px 0 rgba(21, 21, 21, 0.21);
$border-radius-input: 6px;
$border-radius-lg: 9px;
$transition-duration-default: 300ms;
$transition-duration-input: 110ms;

$z-index-0: -100; /* Video */
$z-index-1: 100; /* Backgrounds */
$z-index-2: 200; /* Content, buttons */
$z-index-3: 300; /* Captions */
$z-index-4: 400; /* Meta */
$z-index-5: 500; /* Avatars, logos */
$z-index-6: 600; /* Sidebars, overlays */
$z-index-7: 700; /* Modals, transitions */
$z-index-8: 800; /* Navs */
$z-index-9: 900; /* Topbars, tooltips */
$z-index-10: 1000; /* Dev */

$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;
$screen-xxl-min: 1400px;
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
$screen-lg-max: ($screen-xl-min - 1);
$screen-xl-max: ($screen-xxl-min - 1);

$container-sm: 540px;
$container-md: 720px;
$container-lg: 960px;
$container-xl: 1140px;
$container-xxl: 1320px;

$ease-circ: cubic-bezier(0.11, 0.77, 0.28, 0.92);
$ease-expo: cubic-bezier(0.19, 1, 0.22, 1);
$red-alert: var(--Content-Color-Status-Red-Badge-bg, #db0b0b);
$yellow-alert: #ffce1f;
$highValueBorderColor: #db0b0b;
$mediumValueBorderColor: #b59216;
$highValueBGColor: #fde6e8;
$mediumValueBGColor: #fffae9;
$borderColor5: #e5e5ef;
$greenValueBorderColor: #488d68;
$greenValueBGColor: #f0f9f4;
$green-alert: #66c793;
$table-bg: #eceaea;
$light-gray: #838383;
$high-color: #db0b0b;
$medium-color: #ffce1f;
$normal-color: #66c793;
$high-bg: #feefef;
$medium-bg: #fffae9;
$normal-bg: #f0f9f4;

:root {
  --color-rca-blue: rgb(41, 83, 168);
  --color-rca-blue-hover: rgb(22, 60, 137);
  --color-rca-blue-light: rgba(106, 145, 223, 0.897);
  --color-rca-gray: rgb(173, 185, 202);
  --color-rca-grayrow: rgb(228, 228, 228);
  --color-readable-dark: #333;
  --color-readable-dark-dim: #555;
  --color-background: #fff;
  --color-warning: #c3a236;
  --color-danger: #df3737;
  --color-danger-hover: #b24141;
  --color-success: #39b84c;
  --color-success-hover: #547d45;
  --color-link: #0099ff;
  --color-link-hover: #1177bb;
  --color-patient-bg: #dae3f3;
}

html {
  scroll-behavior: smooth;
}

body {
  position: relative;
  width: 100%;
  letter-spacing: 0.021rem;
  font-family: $font-body;
  font-size: $font-size-md;
  font-weight: var(--font-weight-regular);
  color: var(--color-readable-dark);
  line-height: 1.6;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-primary;
  font-weight: $font-weight-bold;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  -moz-appearance: button;
  cursor: pointer;
}

img {
  max-width: 100%;
  object-fit: contain;
}

label {
  font-size: 12px;
}

a:link,
a:visited {
  color: var(--color-rca-blue);
}
a:active,
a:hover {
  color: var(--color-rca-blue-light);
}

*:focus {
  outline: var(--color-rca-blue) auto 1px;
  outline-color: var(--color-rca-blue);
  outline-style: auto;
  outline-width: 1px;
}

b,
strong {
  font-weight: $font-weight-bold;
}

/* 
  * Overwriting bootstrap classes with anelto signature color 
*/

.btn-primary {
  color: #fff;
  background-color: var(--color-rca-blue) !important;
  border-color: var(--color-rca-blue) !important;
}

.btn-glow-primary {
  box-shadow: none !important;
}

.bg-primary {
  background-color: var(--color-rca-blue) !important;
}

.page-item.active .page-link {
  color: #fff;
  background-color: var(--color-rca-blue) !important;
  border-color: var(--color-rca-blue) !important;
}
.th-text {
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
}
.text-rca {
  font-size: 14px !important;
  font-family: Arial, Helvetica, sans-serif !important;
  font-weight: 500 !important;
}
.rca-btn-sm {
  width: 166px;
}
.btn-sm {
  font-size: 14px !important;
  font-family: Arial, Helvetica, sans-serif !important;
  font-weight: 600 !important;
}
.rs-btn-primary {
  color: #ffffff;
  font-size: 14px !important;
  font-family: Arial, Helvetica, sans-serif !important;
  font-weight: 500 !important;
}
.rs-btn-sm {
  padding: 8px 38px !important;
  margin-right: 13px;
  font-size: 14px !important;
  line-height: 1.42857143;
  border-radius: 6px;
  border-radius: 0.15rem !important;
}

.pagination {
  font-size: 11px !important;
}

.dropdown-menu > a.active,
.dropdown-menu > a:active,
.dropdown-menu > a:focus,
.dropdown-menu > a:hover {
  background: var(--color-rca-blue) !important;
  color: #fff;
}

.dropdown-menu > a {
  font-size: 11px !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: var(--rca-grayrow) !important;
}
.page-content {
  background: var(--rca-gray) !important;
}
.bg-gray {
  background: var(--rca-gray) !important;
}
.bold-text {
  font-weight: 500;
}
.text-arial {
  font-family: Arial, Helvetica, sans-serif;
}
.font-14 {
  font-size: 14px;
}
.dropdown-item:hover {
  color: white !important;
  text-decoration: none;
}
.patient-global-filter .rs-picker-toggle-wrapper {
  max-width: 150px !important;
  width: 160px !important;
}
.dropdown-item:hover {
  color: white !important;
  text-decoration: none;
}

.box-border {
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.text-ellipse {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lead {
  color: black;
}
.subject-scroll {
  max-height: 70vh;
  position: relative;
  overflow: scroll;
}
.rs-btn-primary {
  color: #ffffff;
  background-color: var(--color-rca-blue) !important;
}
.rs-btn-primary:focus {
  outline: 1px solid var(--color-rca-blue) !important;
}
.rs-btn-ghost.rs-btn-green:focus {
  background-color: #fff;
  outline: 1px solid var(--color-rca-blue) !important;
}
.rs-btn-ghost.rs-btn-red:focus {
  background-color: #fff;
  outline: 1px solid var(--color-rca-blue) !important;
}
span.rs-btn-toggle:focus {
  outline: 2px solid var(--color-rca-blue) !important;
}
.rs-modal-backdrop.fade.in {
  opacity: 0.4 !important;
}
.rs-picker-menu {
  z-index: $z-index-6;
}
.rs-picker-menu.fade.in {
  opacity: 1 !important;
}
.rs-picker-disabled {
  opacity: 0.5 !important;
}
.rs-dropdown {
  width: 100%;
  z-index: $z-index-7;
}
.rs-dropdown-toggle,
.rs-dropdown-toggle.rs-btn {
  width: 100%;
  text-align: left;
}
.rs-btn,
.rs-picker-default .rs-picker-toggle.rs-btn {
  height: $height-input;
}
.rs-picker-toggle-value,
.rs-picker-toggle .rs-picker-toggle-placeholder {
  line-height: 24px;
  margin-right: 4px;
}
.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  top: 9px;
}
.rs-picker-default.rs-picker-disabled .rs-picker-toggle {
  background: #fff !important;
}
.rs-picker-value-count {
  align-self: center;
  display: inline-flex;
}
.rs-modal {
  margin-bottom: 30px;
}
.rs-modal-header {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--color-rca-grayrow);
}
.rs-picker-date-menu {
  z-index: 999999 !important;
  width: 280px;
  display: block;
  margin: 0 auto;
}
.table-responsive {
  overflow: visible;
}
.rs-modal-wrapper {
  display: flex;
  align-items: center;
}
