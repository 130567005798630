$color-rca-blue: rgb(41, 83, 168);
$color-rca-blue-hover: rgb(22, 60, 137);
$color-rca-gray: rgb(173, 185, 202);
$color-rca-grayrow: rgb(228, 228, 228);
$color-readable-dark: #333;
$color-readable-dark-dim: #555;
$color-alert-default: #d0d0d0;
$color-alert-success: #5cbd6a;
$color-alert-warning: #dbab31;
$color-alert-danger: #e14d4d;
$color-alert-black: #000000;
$color-alert-gray: #bbbbbb;
$color-input: #fcfdfd;
$color-input-hover: #ffffff;
$border-color1: #70bfbf;
$border-color2: #e2e2e2;
$border-color3: #e8e7e7;
$border-color4: #888787;
$default-bg: #f5f5f5;
$gray-bg: #f8f8fe;
$text-color: #464646;
$green: #488d68;
$red: #db0b0b;
$redbg: #fde6e8;
$blue: #030575;
$complete: #0d74e3;

$font-primary: "Mada", sans-serif;
$font-body: "Poppins", "Arial", Helvetica, sans-serif;
$font-patient: "Libre Franklin", sans-serif;
$font-data: "Roboto", sans-serif;
$default-font: "Montserrat";

$font-size-sm: 12px;
$font-size-md: 14px;
$font-size-lg: 16px;
$font-size-xl: 18px;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$width-sidebar: 88px;
$height-input: 38px;
$height-label: 22px;
$box-shadow-button: 0 3px 7px 0 rgba(21, 21, 21, 0.21);
$border-radius-input: 6px;
$border-radius-lg: 9px;
$transition-duration-default: 300ms;
$transition-duration-input: 110ms;

$z-index-0: -100; /* Video */
$z-index-1: 100; /* Backgrounds */
$z-index-2: 200; /* Content, buttons */
$z-index-3: 300; /* Captions */
$z-index-4: 400; /* Meta */
$z-index-5: 500; /* Avatars, logos */
$z-index-6: 600; /* Sidebars, overlays */
$z-index-7: 700; /* Modals, transitions */
$z-index-8: 800; /* Navs */
$z-index-9: 900; /* Topbars, tooltips */
$z-index-10: 1000; /* Dev */

$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;
$screen-xxl-min: 1400px;
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
$screen-lg-max: ($screen-xl-min - 1);
$screen-xl-max: ($screen-xxl-min - 1);

$container-sm: 540px;
$container-md: 720px;
$container-lg: 960px;
$container-xl: 1140px;
$container-xxl: 1320px;

$ease-circ: cubic-bezier(0.11, 0.77, 0.28, 0.92);
$ease-expo: cubic-bezier(0.19, 1, 0.22, 1);
$red-alert: var(--Content-Color-Status-Red-Badge-bg, #db0b0b);
$yellow-alert: #ffce1f;
$highValueBorderColor: #db0b0b;
$mediumValueBorderColor: #b59216;
$highValueBGColor: #fde6e8;
$mediumValueBGColor: #fffae9;
$borderColor5: #e5e5ef;
$greenValueBorderColor: #488d68;
$greenValueBGColor: #f0f9f4;
$green-alert: #66c793;
$table-bg: #eceaea;
$light-gray: #838383;
$high-color: #db0b0b;
$medium-color: #ffce1f;
$normal-color: #66c793;
$high-bg: #feefef;
$medium-bg: #fffae9;
$normal-bg: #f0f9f4;

.sidebar::selection {
  color: #fff;
  background: #11101d;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: $width-sidebar;
  background: #fff;
  z-index: $z-index-6;
  transition: all 0.5s ease;
  border-right: 1px solid $color-rca-gray;
  box-shadow: 2px 0px 8px 0px #8e8b8b33;
  font-size: 10px;
  font-family: "Poppins", sans-serif;
}
.sidebar a {
  color: inherit;
}
.sidebar img {
  height: 60px;
}
.sidebar ul li svg {
  object-fit: contain;
  width: 36px;
  display: inline-block;
}
.sidebar.active {
  .close-btn i {
    padding-right: 21px;
  }
}
.sidebar .logo_content .logo {
  color: #fff;
  display: flex;
  height: 50px;
  width: 100%;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s ease;
}
.logo_content .logo i {
  font-size: 28px;
  margin-right: 5px;
}
.logo_content .logo .logo_name {
  font-size: 20px;
  font-weight: 400;
}
.sidebar #btn {
  position: absolute;
  color: #483dc7;
  top: 45px;
  left: 100%;
  font-size: 22px;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  transform: translateX(-50%);
  cursor: e-resize;
}
.sidebar ul {
  margin-bottom: 7px;
}

.sidebar ul li {
  position: relative;
  height: 46px;
  width: 100%;
  margin: 0 3px;
  list-style: none;
  line-height: 50px;
}
.sidebar ul li .tooltip,
.sidebar .profile-name {
  position: absolute;
  left: 140px;
  top: 0;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  height: 35px;
  width: auto;
  background: #fff;
  line-height: 35px;
  text-align: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  transition: 0s;
  opacity: 0;
  pointer-events: none;
  display: block;
}
.patient {
  width: 100%;
  padding: 0px 2px;
}

.sidebar.active ul li .link .patient-link-name {
  margin-left: 25px;
}

.sidebar.active .patient {
  width: 35px;
}
.patient img {
  padding: 0px;
  height: 20px;
}
.patient img:first-child {
  margin-left: 27px;
}

.sidebar.active ul li .tooltip,
.sidebar.active .my-profile:hover .tooltip {
  display: none;
}
.sidebar ul li:hover .tooltip,
.sidebar .my-profile:hover .tooltip {
  transition: all 0.5s ease;
  opacity: 1;
  top: 50%;
  font-size: 10px;
}

.sidebar .my-profile:hover {
  cursor: pointer;
}

.sidebar .my-profile > div {
  width: 100%;
  display: flex;
  justify-content: center;
  max-width: 116px;
  height: 50px;
}

.sidebar ul li .link {
  width: 76px;
  color: #11101d;
  align-items: center;
  text-decoration: none;
  border-radius: 0%;
  white-space: nowrap;
  padding: 0px 9px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  img,
  svg {
    height: 62px;
    padding: 16px;
    max-width: 86px;
  }
  a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 62px;
    height: 62px;
    min-width: 62px;
  }
}

.sidebar.active ul li .link {
  width: 100%;
}

.sidebar .link {
  display: block;
  height: 52px;
}
.sidebar .link .links_name,
.profile-name {
  font-size: 11px;
  font-weight: 400;
  opacity: 0;
  pointer-events: none;
  letter-spacing: 0.6px;
  padding-top: 2px;
  transition: 0s;
  text-transform: uppercase;
}
.sidebar.active .link .links_name,
.sidebar.active {
  transition: all 0.3s ease;
  opacity: 1;
  pointer-events: auto;
}

.profile_content .profile .profile_details {
  display: flex;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  white-space: nowrap;
  transition: all 0.4s ease;
}
.sidebar.active ~ .profile .profile_details {
  opacity: 1;
  pointer-events: auto;
}
.profile .profile_details img {
  height: 45px;
  width: 45px;
  object-fit: cover;
  border-radius: 0px;
}
.profile .profile_details .name_job {
  margin-left: 10px;
}
.profile .profile_details .name {
  font-size: 15px;
  font-weight: 400;
}
.profile .profile_details .job {
  font-size: 12px;
}

.menuButton {
  width: 100%;
  background: transparent;
  transition: all 0.5s ease;
  cursor: pointer;
  padding: 14px;

  svg {
    display: inline-block;
    width: 38px;
    height: 38px;
  }
}
.logoutBtn {
  width: 42px;
  height: 42px;
  color: white;
  border: 2px solid $color-readable-dark-dim;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 8px;
  margin: 15px 11px 15px 15px;

  svg {
    width: 25px;
    object-fit: contain;
    height: 100%;

    path {
      stroke: $color-readable-dark;
    }
  }
}
.tooltip {
  font-size: 14px !important;
  height: auto !important;
  width: 150px !important;
  color: $color-rca-blue !important;
}
.user-profile-img {
  height: 46px !important;
  padding: 6px 23px !important;
}

.link:not(.noLink):focus {
  outline: none;

  &:focus-visible {
    img,
    svg {
      outline: auto;
    }
  }
}
.logoutLink:focus {
  outline: none;

  .logoutBtn:focus-visible {
    outline: var(--color-rca-blue) auto 1px;
    outline-color: var(--color-rca-blue);
    outline-offset: 12px;
    border-radius: 0px;
  }
}

.idleContainer {
  padding-left: 10px;
  margin-top: 7px;
  align-self: center;
  user-select: none;
  pointer-events: none;
}
