.status-gray {
  background-color: #e0dede96;
}

.observation-head {
  background-color: rgb(230, 252, 255);
  border-bottom-width: 1px !important;
  color: black;
  font-weight: 600;
  border-left: 1px solid rgba(0, 0, 0, 0.521);
}

.font-bold {
  font-weight: 700;
}

.text-danger {
  color: tomato !important;
}

.bg-danger-light {
  background-color: rgba(255, 99, 71, 0.233);
}
.bg-warning-light {
  background-color: rgba(255, 255, 0, 0.233);
}

.billing table {
  display: table;
  border: 1px solid var(--color-rca-gray);
  border-collapse: collapse;
  font-size: medium;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
.billing thead tr {
  background-color: var(--color-rca-blue);
  color: #ffffff;
  text-align: left;
  font-size: 1rem;
}

.billing th,
.billing td {
  padding: 3px 7px !important;
  vertical-align: middle;
  min-width: 50px;
  box-sizing: border-box;
  text-align: left;
}

.billing tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}
