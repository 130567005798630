.rs-message .rs-message-icon-wrapper {
  position: absolute !important;
  top: -5% !important;
  left: -2% !important;
  line-height: 1;
}

.rs-message .rs-message-icon-wrapper .rs-icon {
  font-size: 19px !important;
  line-height: 1.66666667;
}
