#provider_form label {
  font-size: 14px;
}
.back-btn {
  padding: 10px;
}
.back-btn:hover {
  color: #2953a8;
  cursor: pointer;
  background-color: #2953a832;
  border-radius: 50%;
}
