.threshold-range {
  display: flex;
  flex-direction: row;
}
.threshold-range:before,
.threshold-range:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid;
  margin: auto;
}
.threshold-range:before {
  margin-right: 10px;
}
.threshold-range:after {
  margin-left: 10px;
}
